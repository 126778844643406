import React from "react";
import {
  Box,
  Flex, Wrap,
  WrapItem,
  Link,
  Center,
  List,
  ListItem,
  Text
} from "@chakra-ui/react";

import Logo from "../ui/Logo";

import { Icon } from "@chakra-ui/react"

import {
  AiOutlineEnvironment,
  AiOutlinePhone,
  AiOutlineSend
} from "react-icons/ai";

import Move from "../ui/IconAnimations";

import ModalWindow from "../modals/ModalWindow";

const MenuItem = ( { children, to = "/", ...rest }: any ) => {

  const onClick = (e: any) => {
    e.preventDefault();

    const el = document.getElementById(e.target.attributes.href.value.slice(1));
    if (el) {
      el.scrollIntoView({behavior:"smooth"});
    }
  };

  return (
    <WrapItem
      w={{ xxl: "auto", xl: "auto", xs: "auto", lg: "auto", md: "auto", sm: "100%" } }
      textAlign={{ sm: "center" }}
      display={{ sm: "block" }}
      mt="0px"
      borderBottom={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "1px solid #ffffff", sm: "1px solid #ffffff" } }
      pb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "20px", sm: "20px" }}
    >

      <Link
        onClick={ onClick }
        href={to}
        mb={{ base: 8, sm: 0 }}
        mr={{ base: 0, sm: 0 }}
        display="block"
        textTransform="uppercase"
        {...rest}
      >
        {children}
      </Link>

    </WrapItem>

  );
};

const CloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
          fill="white"
          d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg>
);

const MenuIcon = () => (
    <svg
      width="24px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const Header = ( ( props : any ) => {

  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <>
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      p={{ xxl: "0rem 0.75rem", xl: "0px 50px", xs: "0px 50px" }}
      fontFamily="Century Gothic Bold"
      maxW={{ xl: "1200px" }}
      mt="45px"
      fontSize="14px"
      {...props}
    >

      <Flex
        display={{ base: "block", lg: "none" }}
        w="100%"
      >

        <Box
          float="left"
          w="50%"
          ml="3%"
        >
          <Logo
            w="200px"
          />
        </Box>

        <Box
          onClick={toggleMenu}
          float="right"
          m="6%"
        >
          <Icon as={ show
            ? CloseIcon
            : MenuIcon
          }
           color="#ff0000"
           border="1px solid #ff0c0c1a"
           p={5}
          />
        </Box>

      </Flex>

      <Flex
        display={{ base: show ? "block" : "none", lg: "flex" }}
        flexBasis={{ base: "100%", md: "auto" }}
        w="100%"
      >

        <Box
          align="center"
          w={{ xxl: "85%", xl: "83%", xs: "83%" }}
        >

          <Wrap spacing={6}
            fontSize={{ xxl: "14px", xl: "12px", xs: "12px" }}
            display={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "block" }}
            bg={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ffffff78", sm: "#ffffff78" }}
            mt={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "2%", sm: "2%" }}
          >

            <MenuItem to="#aboutUs">О Компании</MenuItem>
            <MenuItem to="#services">Наши Услуги</MenuItem>
            <MenuItem to="#feedback">Получить консультацию</MenuItem>
            <MenuItem to="#faq">Помощь</MenuItem>
            <MenuItem to="#contacts">Контакты</MenuItem>

          </Wrap>

        </Box>

        <Box
          textAlign={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "center", sm: "center" }}
          bg={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ff0000ad", sm: "#ff0000ad" }}
          color={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ffffff", sm: "#ffffff" }}
          p={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "5px", sm: "5px" }}
        >
          <Link href="tel:+998999111234"
                fontSize={{ xxl: "14px", xl: "12px", xs: "12px", lg: "12px", md: "16px", sm: "16px" }}>

            <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
              <Icon
                as={AiOutlinePhone}
                bg="#ff0000"
                color="#ffffff"
                p="3px"
                borderRadius="50%"
                w="20px"
                h="20px"
              />
            </Move> +998 (99) 911-12-34
          </Link>
        </Box>

      </Flex>

    </Flex>

    <Flex
      align="center"
      maxW={{ xl: "1200px" }}
      p={{ xxl: "0px 0px 0px 15px", xl: "0px 50px", xs: "0px 50px" }}
      w="100%"
      m="20px 0px !important"
      display={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "block", sm: "block" }}
    >

      <Box
        align="left"
        w="30%"
        display={{ base: "none", lg: "block" }}
      >
        <Logo
          w="250px"
        />
      </Box>

      <Flex
        w={{ xxl: "35%", xl: "35%", xs: "35%", lg: "35%", md: "100%", sm: "100%" }}
        borderBottom={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "1px solid #bbf3ff", sm: "1px solid #bbf3ff" }}
        mb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "10px", sm: "10px" }}
        pb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "10px", sm: "10px" }}
      >

        <Center
          w={{ xxl: "20%", xl: "20%", xs: "20%", lg: "20%", md: "20%", sm: "20%" }}
          float="left"
        >
          <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
            <Icon
              as={AiOutlinePhone}
              fontSize="30px"
              color="#ff0000"
              borderRadius="50%"
              p="5px"
              bg="#ffffff54"
            />
          </Move>
        </Center>

          <List
          flex="1">

            <ListItem
              color="#83898c"
              fontSize={{ xxl: "13px", xl: "11.5px", xs: "8.8px" }}
            >
              Работаем: Пн-Пт с 09:00 до 19:00
            </ListItem>

            <ListItem>
              <Link
                href="tel:+998951440060"
                w="100%"
                float="left"
                color="#ff0000"
                fontSize={{ xxl: "22.5px", xl: "20px", xs: "16px" }}
               fontWeight={800}>
                +998 (95) 144-00-60
              </Link>

            </ListItem>

          </List>

      </Flex>

      <Box
        w={{ xxl: "auto", xl: "auto", xs: "auto", lg: "auto", md: "100%", sm: "100%" }}
        borderBottom={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "1px solid #bbf3ff", sm: "1px solid #bbf3ff" }}
      >

        <ModalWindow coordinates={{
          lat:41.3272022,
          lng:69.3375053
        } } address="Адрес головного офиса: Г. Ташкент
            Мирзо Улугбекский район, улица Буюк Ипак Йули д.152"
            margin="0px"
            link="https://yandex.ru/maps/org/204496205584"
            height={{ xxl: "40px", xl: "40px", xs: "40px", lg: "40px", md: "80px", sm: "80px" }}
        />

        <br />

        <ModalWindow coordinates={{
          lat:40.8538318,
          lng:69.589162
        } } address="Адрес офиса: Г. Алмалык"
          margin="7px"
          link="https://yandex.uz/maps/geo/1649219931/?ll=69.573921%2C40.797227&z=12.12"
          height={{ xxl: "40px", xl: "40px", xs: "40px", lg: "40px", md: "40px", sm: "40px" }}
        />

      </Box>

      <Flex
        w={{ xxl: "30%", xl: "30%", xs: "34%", lg: "38%", md: "100%", sm: "100%" }}
      >

        <Center
          w="20%"
          float="left"
        >

          <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >

            <Icon
              as={AiOutlineSend}
              fontSize="30px"
              color="#0088cc"
              borderRadius="50%"
              p="5px"
              bg="#ffffff54"
            />

          </Move>
        </Center>

        <List
          flex="1">

          <ListItem>

            <Link
              href="https://t.me/Tvoy_Dom_V_Tashkente"
              color="#ff0000"
              fontSize={{ xxl: "13px", xl: "13px", xs: "11px" }}
              float="left"
              p={{ xxl: "5px", xl: "5px", xs: "5px", lg: "2px", md: "7px", sm: "7px" }}
              fontWeight={800}
              target="__blank"
            >
              Телеграм канал Ташкент
            </Link>

          </ListItem>

          <ListItem>

            <Link
              href="https://t.me/tvoy_dom_v_olmaliqe"
              color="#ff0000"
              fontSize={{ xxl: "13px", xl: "13px", xs: "11px" }}
              float="left"
              mt={{ xxl: "5px", xl: "5px", xs: "6px", lg: "5px", md: "0px", sm: "0px" }}
              p={{ xxl: "5px", xl: "5px", xs: "5px", lg: "2px", md: "7px", sm: "7px" }}
              fontWeight={800}
              target="__blank"
            >
              Телеграм канал Алмалык
            </Link>

          </ListItem>

        </List>

      </Flex>

    </Flex>

    </>
  );

} );

export default Header;
